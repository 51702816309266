import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

//import { ThemeProvider } from "@mui/styles";

export const NOTOSANSFONT = "Noto Sans";
const theme = createTheme({
  APPBARHEIGHTMD: "64",
  APPBARHEIGHTSM: "74",
  APPBARHEIGHTLG: "85px",
  palette: {
    primary: {
      main: "#589774",
      light: "#ffffff",
      dark: "#014A8C",
      dull: "#eeeeee",

    },
    secondary: {
      light: "#A8A0A0",
      main: "#4285F4",
      dark: "#DB6A66",
    },
    text: {
      primary: "#707070",
      secondary: "#589774",
      red: "#EB6161",
      black: "#272727",
      green: "#589774",
      bluishgrey: "#7E84A3",
    },
    background: {
      primary: "#80BED5",
      secondary: "#5EAD86",
      tertiary: "#DB6A66",
      category: "#5D5897",
      lightgrey: "#F7F7F7",
      choosefile: "#F4F4F4",
      imgpicker: "#F5F5F5",
      green1: "#6DA385",
      green2: "#46775C",
    },
  },
  typography: {
    fontFamily: "niveau-grotesk",
    allVariants: {
      color: "#1D1D1F",
      fontWeight: "normal",
    },
    h6: {
      color: "#ffffff",
    },
    h5: {
      color: "#1D1D1F",
    },
    caption: {
      color: "#589774",
    },
    body2: {
      color: "#707071",
    },

    button: {
      fontSize: 16,
      textTransform: "none",
    },
  },
  borders: {
    primary: "#C0C0C1",
  },
  background: {
    primary: "#ffffff",
    green: "#44B728",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#589774",
          "&:hover": {
            backgroundColor: "#7eb496",
          },
          borderRadius: 30,
          height: "2.5rem",
          color: "#ffffff",
          paddingLeft: "2%",
          paddingRight: "2%",
          fontSize: 16,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          //height: "2.5rem",
          // "&:hover fieldset": {
          //   borderColor: "#34a0fe",
          // },
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#014A8C",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});

export const APPBARHEIGHT = 85;
export const SIDE_PADDING = "7%";
export const TOP_PADDING = "3%";

const AppTheme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppTheme;
