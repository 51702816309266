export const HOME = "/";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const FORGOTPASSWORD = "/forgotpassword";
export const INFLUENCERS_LIST_YT = "/influencersyt";
export const INFLUENCERS_LIST_FB = "/influencersfb";
export const INFLUENCERS_LIST_INSTA = "/influencersinsta";
export const INFLUENCERS_LIST_TELE = "/influencerstele";
export const PROFILE = "/profile";
export const USERS = "/users";
export const DASHBOARD = "/campaigns";
export const ALL_CAMPAIGNS = "/all";
export const ACTIVE_CAMPAIGNS = "/active";
export const UNDERREVIEW_CAMPAIGNS = "/underreview";
export const CLOSED_CAMPAIGNS = "/closed";
export const DRAFT_CAMPAIGNS = "/draft";
export const PAUSED_CAMPAIGNS = "/paused";
export const PRIVATE_CAMPAIGNS = "/private";
export const NEW_CAMPAIGN = "/campaigns/new";
export const HOMEPAGE = "/homepage"
export const ADMIN_CAMPAIGNS = "/admin/campaigns";
export const CAMPAIGN_APPLICANTS = "/applicants";
export const TRACK_CAMPAIGN = "/track";

export const INFLUENCER_DISOVER_CAMPAIGNS = "/campaigns/discover";
export const INFLUENCER_APPLIED_CAMPAIGNS = "/campaigns/applied";
export const INFLUENCER_APPROVED_CAMPAIGNS = "/campaigns/approved";
