import { FORGOTPASSWORD, HOME, LOGIN, SIGNUP } from "../api/routes";

export const DEFAULT_ERROR = "Something went wrong. Please try again later.";
export const HIDE_APPBAR_ROUTES = {
  [LOGIN]: true,
  [SIGNUP]: true,
  [FORGOTPASSWORD]: true,
  [HOME]: true,
};

export const DrawerWidth = 200;
export const VIDEO_TYPES = [
  "Integrated Video",
  "Dedicated",
  "Short Clip",
  "Community Post",
];

export const VIDEO_STATUSES = ["Confirmed", "In Progress", "Completed"];
export const INFLUENCER_GENDERS = ["M", "F", "O", "NA"];
export const GENTERATE_PWD_PROPERTIES = {
  length: 10,
  numbers: true,
  symbols: false,
  lowercase: true,
  uppercase: true,
};

export const USER_ROLES = [
  "SUPER_ADMIN",
  "ADMIN",
  "MODERATER",
  "INFLUENCER",
  "CLIENT_ADMIN",
];

export const CREATE_USER_ROLES_SUPER_ADMIN = ["ADMIN", "MODERATER"];
export const CREATE_USER_ROLES__ADMIN = ["MODERATER"];

export const ROWS_PER_PAGE_INFLUENCER_OPTIONS = [100, 200, 500];
export const USER_TYPES = ["INTERNAL", "CLIENT_ADMIN", "INFLUENCER"];
export const PLATFORMS = ["FACEBOOK", "INSTAGRAM", "YOUTUBE", "TELEGRAM"];
export const CAMPAIGN_FORM_FOLLOWERS_LIST = [
  { label: "Any", value: "*" },
  { label: "Nano (1k-10k)", value: "1000-10000" },
  { label: "Macro(10k-50k)", value: "10000-50000" },
  {
    label: "Mega (250k+)",
    value: "50000-250000",
  },
];

export const CAMPAIGN_FORM_NO_OF_INFLUEMCERS = ["1", "2-10", "10-50", "50+"];
export const EMPTYARR = [];

export const CAMPAIGN_FORM_PAYMENT_TYPES = ["Barter", "Cash Payment"];
export const CAMPAIN_FORM_GENDERS = ["Any", "Male", "Female"];
export const CAMPAIGN_STATUSES = [
  "Under Review",
  "Rejected",
  "Cancelled",
  "Closed",
  "Active",
  "Ongoing",
  "Paused",
];
